<template>
  <d-remote-monitoring-indicators-view :indicator-id="id" />
</template>

<script>

export default {
  name: 'RemoteMonitoringIndicatorsView',
  data() {
    return {
      id: null,
    };
  },

  mounted() {
    this.id = this.$attrs.id;
  },
};
</script>
